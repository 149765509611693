<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <div class="mt-0">
        <b-card
          v-for="(room, index) in currentReservation.package_reservations"
          :key="index"
          header-tag="header"
          class="mt-2"
          :class="{ 'border-danger': errors }"
        >
          <template #header>
            <h3>
              Chambre {{ index + 1 }} - Occupation
              {{ occupationLabel(room.occupation_type.type) }}
            </h3>
          </template>
          <b-row v-if="isValidated && hasErrors" class="mb-1">
            <b-col>
              <b-alert v-if="!hasPrimary" variant="danger" show class="mb-0">
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  <b>Attention</b> - Il n'y a pas de contact principal associé à
                  votre réservation. Veuillez assigner l'un des voyageurs comme
                  contact principal.
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <div class="d-flex align-items-center mb-3 mt-1">
                <feather-icon icon="UsersIcon" size="18" />
                <h4 class="mb-0 ml-75">Voyageurs dans la chambre</h4>
              </div>

              <div
                v-for="(traveler, traveler_index) in getRoomTravelers(room)"
                :key="traveler_index"
              >
                <div
                  v-if="traveler.id != undefined"
                  class="d-flex justify-content-between align-items-center mt-1"
                >
                  <div class="d-flex">
                    <div class="mr-1">
                      <b-avatar variant="light-success" size="34">
                        <feather-icon icon="CheckIcon" size="18" />
                      </b-avatar>
                    </div>
                    <div class="profile-user-info">
                      <h6 class="mb-0">
                        {{ traveler.first_name }}
                        {{ traveler.last_name }}
                        <small v-if="traveler.reservation_owner"
                          ><i>- Contact Principal</i></small
                        >
                      </h6>
                      <small class="text-primary text-darken-2">
                        {{ traveler.type === "ADULT" ? "Adulte" : "Enfant" }}
                      </small>
                    </div>
                  </div>

                  <div>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="danger"
                      class="btn-icon ml-auto float-right"
                      size="sm"
                      style="line-height: 100%; margin-right: 1px"
                      @click="deleteTraveler(traveler, room.id)"
                      :disabled="
                        !(
                          currentReservation.status != 'SUBMITTED' ||
                          canViewBasedOnRole(currentAuthUser, [
                            Roles.ORGANIZATION_ADMIN.role,
                            Roles.ORGANIZATION_COORDINATOR.role,
                          ])
                        )
                      "
                    >
                      <feather-icon icon="Trash2Icon" />
                      <span style="margin-left: 5px">Supprimer</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon ml-auto float-right"
                      size="sm"
                      style="line-height: 100%; margin-right: 3px"
                      v-b-modal="
                        'edit-traveler-' + traveler.id
                      "
                      :disabled="
                        !(
                          currentReservation.status != 'SUBMITTED' ||
                          canViewBasedOnRole(currentAuthUser, [
                            Roles.ORGANIZATION_ADMIN.role,
                            Roles.ORGANIZATION_COORDINATOR.role,
                          ])
                        )
                      "
                    >
                      <feather-icon icon="EditIcon" />
                      <span style="margin-left: 5px">Modifier</span>
                    </b-button>
                  </div>
                  <GroupReservationTravelerModalUpdate
                    :has-primary="hasPrimary"
                    @updateTraveler="updateTraveler"
                    :current-traveler="traveler"
                    :current-room="room"
                    :is-public="isPublic"
                  />
                </div>
                <div
                  v-else
                  class="d-flex justify-content-start align-items-center mt-1"
                >
                  <div class="mr-1">
                    <b-avatar variant="light-warning" size="34">
                      <feather-icon icon="UserIcon" size="18" />
                    </b-avatar>
                  </div>
                  <div class="profile-user-info">
                    <!-- Should change to voyageur if not transat -->
                    <h6 class="mb-0">
                      {{
                        traveler.type === 'ADULT'
                          ? "Adulte"
                          : "Enfant"
                      }}
                      #{{ traveler_index + 1 }}
                      {{
                        traveler_index >= room.occupation_type.adult_min
                          ? "(optionnel)"
                          : ""
                      }}
                    </h6>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                    class="btn-icon ml-auto"
                    size="sm"
                    style="line-height: 100%"
                    @click="showAddTraveler(index, traveler_index)"
                  >
                    <feather-icon icon="UserPlusIcon" />
                    <span style="margin-left: 5px">Inscrire</span>
                  </b-button>
                  <GroupReservationTravelerModal
                    :ref="`add-traveler-modal-${index}-${traveler_index}`"
                    :has-primary="hasPrimary"
                    :current-room="room"
                    :is-public="isPublic"
                    :is-child-traveler="
                      traveler_index >= room.occupation_type.adult_min
                    "
                    @submitTraveler="addTraveler"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="ml-2">
              <div class="d-flex align-items-center mb-3 mt-1">
                <feather-icon icon="SettingsIcon" size="18" />
                <h4 class="mb-0 ml-75">Préférence de la chambre</h4>
                <div
                  style="margin-left: auto"
                  v-if="
                    currentReservation.status != 'SUBMITTED' ||
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                >
                  <b-button
                    v-if="roomBeingEdited == index"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="success"
                    class="btn-icon ml-auto"
                    size="sm"
                    style="line-height: 100%"
                    @click="saveRoomPreferenceChange(index)"
                    :disabled="
                      !(
                        currentReservation.status != 'SUBMITTED' ||
                        canViewBasedOnRole(currentAuthUser, [
                          Roles.ORGANIZATION_ADMIN.role,
                          Roles.ORGANIZATION_COORDINATOR.role,
                        ])
                      )
                    "
                  >
                    <feather-icon icon="UserPlusIcon" />
                    <span
                      style="margin-left: 5px"
                      @click="updateRoomPreferences(room)"
                      >Sauvegarder</span
                    >
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                    class="btn-icon ml-auto"
                    size="sm"
                    style="line-height: 100%"
                    @click="editRoomPreference(index)"
                    :disabled="
                      !(
                        currentReservation.status != 'SUBMITTED' ||
                        canViewBasedOnRole(currentAuthUser, [
                          Roles.ORGANIZATION_ADMIN.role,
                          Roles.ORGANIZATION_COORDINATOR.role,
                        ])
                      )
                    "
                  >
                    <feather-icon icon="UserPlusIcon" />
                    <span style="margin-left: 5px">Modifier</span>
                  </b-button>
                </div>
              </div>

              <b-row>
                <b-col cols="6" class="border-left pr-2">
                  <div>
                    <h5 class="text-capitalize mb-75">
                      Options supplémentaires
                    </h5>
                    <ul
                      v-if="roomBeingEdited != index"
                      style="list-style: none; padding-inline-start: 2px"
                    >
                      <!--                      <b-card-text v-if="room.preferences.options == undefined || room.preferences.options.length == 0">-->
                      <!--                        Pas de préférence-->
                      <!--                      </b-card-text>-->
                      <li v-if="room.extra_bed">
                        <span class="bullet bullet-sm mr-1 bullet-info"></span>
                        <small>Lit d’appoint supplémentaire</small>
                      </li>
                      <li v-if="room.playpen">
                        <span class="bullet bullet-sm mr-1 bullet-info"></span>
                        <small>Parc pour bébé</small>
                      </li>
                      <li v-if="room.birthday_celebration">
                        <span class="bullet bullet-sm mr-1 bullet-info"></span>
                        <small>Anniversaire à célébrer</small>
                      </li>
                      <li v-if="room.honeymoon">
                        <span class="bullet bullet-sm mr-1 bullet-info"></span>
                        <small>Lune de miel</small>
                      </li>
                      <li v-if="room.reduced_mobility">
                        <span class="bullet bullet-sm mr-1 bullet-info"></span>
                        <small>Mobilité réduite</small>
                      </li>
                      <li v-if="room.requires_wheelchair_assistance">
                        <span class="bullet bullet-sm mr-1 bullet-info"></span>
                        <small>Assistance fauteuil roulant aux aéroports</small>
                      </li>
                    </ul>

                    <b-form-group
                      v-if="roomBeingEdited == index"
                      :disabled="
                        !(
                          currentReservation.status != 'SUBMITTED' ||
                          canViewBasedOnRole(currentAuthUser, [
                            Roles.ORGANIZATION_ADMIN.role,
                            Roles.ORGANIZATION_COORDINATOR.role,
                          ])
                        )
                      "
                    >
                      <div>
                        <b-form-checkbox
                          v-model="
                            currentReservation.package_reservations[index]
                              .extra_bed
                          "
                          class="custom-control-primary mb-1"
                        >
                          Lit d’appoint supplémentaire
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="
                            currentReservation.package_reservations[index]
                              .playpen
                          "
                          class="custom-control-primary mb-1"
                        >
                          Parc pour bébé
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="
                            currentReservation.package_reservations[index]
                              .birthday_celebration
                          "
                          class="custom-control-primary mb-1"
                        >
                          Anniversaire à célébrer
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="
                            currentReservation.package_reservations[index]
                              .honeymoon
                          "
                          class="custom-control-primary mb-1"
                        >
                          Lune de miel
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="
                            currentReservation.package_reservations[index]
                              .reduced_mobility
                          "
                          class="custom-control-primary mb-1"
                        >
                          Mobilité réduite
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-model="
                            currentReservation.package_reservations[index]
                              .requires_wheelchair_assistance
                          "
                          class="custom-control-primary mb-1"
                        >
                          Assistance fauteuil roulant aux aéroports
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6 ">
                  <div>
                    <h5 class="mb-75">Type de lit</h5>
                    <b-card-text v-if="roomBeingEdited != index">
                      {{ setBedOptions(room.bed_option) }}
                    </b-card-text>
                    <div v-if="roomBeingEdited == index">
                      <v-select
                        id="group-type"
                        :options="bedPreferenceOptions"
                        v-model="room.bed_option"
                        :selectable="(option) => !option.value.includes('null')"
                        label="text"
                        :reduce="(text) => text.value"
                        @option:selected="updatePreference"
                        :disabled="
                          !(
                            currentReservation.status != 'SUBMITTED' ||
                            canViewBasedOnRole(currentAuthUser, [
                              Roles.ORGANIZATION_ADMIN.role,
                              Roles.ORGANIZATION_COORDINATOR.role,
                            ])
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2" v-if="!isTransat">
                    <h5 class="mb-75">Étage de la chambre</h5>
                    <b-card-text v-if="roomBeingEdited != index">
                      {{ setFloorOptions(room.floor_preference) }}
                    </b-card-text>
                    <div v-if="roomBeingEdited == index">
                      <v-select
                        id="group-type"
                        v-model="room.floor_preference"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        :options="roomLevelOptions"
                        label="text"
                        :reduce="(text) => text.value"
                        @option:selected="updatePreference"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-card>
        <template #footer class="card-footer">
          <b-button variant="primary" v-on:click="validateSelection()"
            >Suivant</b-button
          >
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButtonGroup,
  BButton,
  BIconFileEarmarkEasel,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
} from "bootstrap-vue";
import GroupReservationTravelerModal from "./GroupReservationTravelerModal.vue";
import GroupReservationTravelerModalUpdate from "./GroupReservationTravelerModalUpdate.vue";
import vSelect from "vue-select";
import Roles from "@/modules/authnz/models/role";

import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { ref } from "@vue/composition-api/dist/vue-composition-api";
export const groupReservationService = new GroupReservationService();
export default {
  components: {
    BAlert,
    BAvatar,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupReservationTravelerModal,
    GroupReservationTravelerModalUpdate,
    BButtonGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: [
    "currentReservation",
    "errors",
    "hasPrimary",
    "currentAuthUser",
    "isChildrenTraveler",
    "isPublic",
  ],
  data() {
    return {
      isValidated: false,
      hasErrors: false,
      hasBasicInfo: false,
      currentReservationPackage: {},
      roomBeingEdited: 0,
      roomPreferencesOption: [
        { value: "Lit d’appoint supplémentaire" },
        { value: "Parc pour bébé" },
        { value: "Anniversaire à célébrer" },
        { value: "Lune de miel" },
        { value: "Mobilité réduite" },
        { value: "Assistance fauteuil roulant aux aéroports" },
      ],
      bedPreferenceOptions: [
        //{text: "Un grand lit", value: "BIG_BED"},
        { text: "Un lit", value: "ONE_BED" },
        { text: "Deux lits", value: "TWO_BEDS" },
        { text: "Lits jumeaux", value: "TWIN_BEDS" },

        { text: "Aucune préférence sélectionné", value: "NO_PREFERENCE" },
        //{text: "Sélectionner une option", value: "null"}
      ],
      bedPreference: "Aucune préférence sélectionné",
      roomLevelOptions: [
        { text: "Rez-de-chaussée", value: "FIRST" },
        { text: "Étage supérieure", value: "UPPER" },
        { text: "Aucune préférence sélectionné", value: "NO_PREFERENCE" },
      ],
      roomLevel: "Pas de préférence",
      roomPreferences: [],
      roomSettings: {},
      currentTravelerBeingAdded: {
        room_index: null,
        traveler_index: null,
      },
      isTransat: true,
    };
  },
  setup(props) {
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();

    let canViewBasedOnRole = (currentRole, allowedRoles) => {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles);
    };

    return {
      displayErrorMessage,
      displaySuccessMessage,
      Roles,
      canViewBasedOnRole,
    };
  },
  methods: {
    showAddTraveler(room_index, traveler_index) {
      this.$refs[
        `add-traveler-modal-${room_index}-${traveler_index}`
      ][0].$children[0].show();
    },
    showUpdateTraveler(room_index, traveler_index) {
      this.$refs[
        `update-traveler-modal-${traveler_index}`
      ][0].$children[0].show();
    },
    editRoomPreference(index) {
      this.roomBeingEdited = index;
    },
    saveRoomPreferenceChange(index) {
      this.roomBeingEdited = null;
    },
    getRoomTravelers(room) {
      let travelers = [];

      const adultTravelers = room.travelers.filter((x) => x.type === "ADULT");
      const childTravelers = room.travelers.filter((x) => x.type !== "ADULT");
      travelers.push(...adultTravelers);

      for (
        let i = 0;
        i < room.occupation_type.adult_min - adultTravelers.length;
        i++
      ) {
        travelers.push({
          type: 'ADULT'
        });
      }

      travelers.push(...childTravelers);
      const maxChildrenCount =
        room.occupation_type.travelers_max - room.occupation_type.adult_min;
      for (let i = childTravelers.length; i < maxChildrenCount; i++) {
        travelers.push({
          type: 'CHILD'
        });
      }

      return travelers;
    },
    addTraveler(traveler, reservation_package_id) {
      this.isValidated = false;
      this.$emit(
        "postTravelerReservationPackage",
        traveler,
        reservation_package_id
      );
    },
    updateTraveler(traveler, package_reservation_id) {
      this.isValidated = false;
      this.$emit(
        "updateTravelerReservationPackage",
        traveler,
        package_reservation_id
      );
    },
    deleteTraveler(traveler, reservation_package_id) {
      this.isValidated = false;
      this.$emit("deleteTravelerAll", traveler, reservation_package_id);
    },
    updatePreference() {
      this.$forceUpdate();
    },
    updateRoomPreferences(room) {
      let roomPref = {
        id: room.id,
        extra_bed: room.extra_bed,
        bed_option: room.bed_option,
        playpen: room.playpen,
        birthday_celebration: room.birthday_celebration,
        honeymoon: room.honeymoon,
        floor_preference: room.floor_preference,
        reduced_mobility: room.reduced_mobility,
        requires_wheelchair_assistance: room.requires_wheelchair_assistance,
        package_id: room.group_package.id,
        occupation: {
          id: room.occupation_type.id,
        },
      };

      this.$emit("updateRoomPreferences", roomPref);
    },
    validateSelection() {
      this.isValidated = true;
      if (!this.hasPrimary) {
        this.hasErrors = true;
      } else {
        this.hasErrors = false;
        this.$emit("nextTab");
      }
    },
  },
  computed: {
    typeToNumberTravelers() {
      const types = {
        SINGLE: 1,
        DOUBLE: 2,
        TRIPLE: 3,
        QUADRUPLE: 4,
      };
      return (type) => types[type];
    },
    occupationLabel() {
      const statusLabel = {
        SINGLE: "Simple",
        DOUBLE: "Double",
        TRIPLE: "Triple",
        QUADRUPLE: "Quadruple",
      };
      return (status) => statusLabel[status];
    },
    setBedOptions() {
      const types = {
        BIG_BED: "Un grand lit",
        TWO_BEDS: "Deux lits",
        ONE_BED: "Un lit",
        NO_PREFERENCE: "Pas de préférence",
        TWIN_BEDS: "Lits jumeaux",
      };
      return (type) => types[type];
    },
    setFloorOptions() {
      const types = {
        FIRST: "Rez-de-chaussée",
        UPPER: "Étage supérieur",
        NO_PREFERENCE: "Pas de préférence",
      };
      return (type) => types[type];
    },
  },
};
</script>

<style>
.border-left {
  border-left: 1px solid;
}
</style>
