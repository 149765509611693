<template>
  <b-modal
    id="modal-select2"
    title="Inscrire un voyageur"
    ok-title="Enregistrer"
    cancel-title="Annuler"
    cancel-variant="outline-secondary"
    size="lg"
    @ok.prevent="submitTravelerWithDebounce"
    no-close-on-backdrop
  >
    <FormTraveler
      :current-room="currentRoom"
      :has-primary="hasPrimary"
      :is-public="isPublic"
      :is-child-traveler="isChildTraveler"
      ref="travelerCreate"
    ></FormTraveler>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormTraveler from "./../FormReservation/FormTraveler";
import { required } from "@core/utils/validations/validations";
import _ from "lodash";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    FormTraveler,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["currentRoom", "hasPrimary", "isChildTraveler", "isPublic"],
  data() {
    return {
      required,
      submitTravelerWithDebounce: null,
    };
  },
  created() {
    this.submitTravelerWithDebounce = _.debounce(this.submitTraveler, 300, {
      leading: true,
      trailing: false,
    });
  },
  methods: {
    submitTraveler() {
      this.$refs.travelerCreate.$refs.simpleRules
        .validate()
        .then((success) => {
          if (success) {
            this.$emit(
              "submitTraveler",
              this.$refs.travelerCreate.traveler,
              this.currentRoom.id
            );
          }
        })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__search,
.style-chooser .vs__open-indicator {
  background: #d5d5d5;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.medical-radios {
  margin-top: 1rem;
}
</style>
