<template>
  <b-modal
    :id="'edit-traveler-' + currentTraveler.id"
    ref="travelerUpdateModal"
    title="Modifier un voyageur"
    ok-title="Enregistrer"
    cancel-title="Annuler"
    cancel-variant="outline-secondary"
    size="lg"
    @ok.prevent="submitTravelerWithDebounce"
    no-close-on-backdrop
  >
    <FormTraveler
      :current-room="currentRoom"
      :has-primary="hasPrimary"
      :is-public="isPublic"
      :current-traveler="currentTraveler"
      :is-child-traveler="currentTraveler.type != 'ADULT'"
      ref="travelerUpdate"
    />
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormTraveler from "./../FormReservation/FormTraveler";
import _ from "lodash";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
    FormTraveler,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["currentTraveler", "currentRoom", "hasPrimary", "isPublic"],
  data() {
    return {
      submitTravelerWithDebounce: null,
    };
  },
  created() {
    this.submitTravelerWithDebounce = _.debounce(this.submitTraveler, 300, {
      leading: true,
      trailing: false,
    });
  },
  methods: {
    submitTraveler() {
      this.$refs.travelerUpdate.$refs.simpleRules
        .validate()
        .then((success) => {
          if (success) {
            let traveler = this.$refs.travelerUpdate.traveler;
            if (Array.isArray(traveler.birthday)) {
              let birthday = new Date(traveler.birthday.join("-"));
              var mm = birthday.getMonth() + 1;
              var dd = birthday.getDate();

              traveler.birthday = [
                birthday.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd,
              ].join("-");
            }
            this.$emit(
              "updateTraveler",
              this.$refs.travelerUpdate.traveler,
              this.currentRoom.id
            );
            this.$refs["travelerUpdateModal"].hide();
          }
        })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__search,
.style-chooser .vs__open-indicator {
  background: #d5d5d5;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.medical-radios {
  margin-top: 1rem;
}
</style>
