import axios from '@axios'
import GroupDTO from './DTO/GroupDTO'

export class GroupService {

  constructor() {
    this.group = new GroupDTO();
  }

  async getGroup(id, publicAccessToken) {

    return new Promise((resolve, reject) => {
      let url = `/groups/${id}`

      if (publicAccessToken != null) {
        url = `/groups/${id}?token=${publicAccessToken}`
      }
      axios
        .get(url).then(response => {
          resolve(this.group.formatGroupFromAPIObject(response.data))
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }



  async getTransportations(id, publicAccessToken) {

    return new Promise((resolve, reject) => {

      let url = `/groups/${id}/transportations`

      if (publicAccessToken != null) {
        url = `/groups/${id}/transportations?token=${publicAccessToken}`
      }

      axios
        .get(url).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  async getAccommodations(id, publicAccessToken) {

    return new Promise((resolve, reject) => {

      let url = `/groups/${id}/accommodations`

      if (publicAccessToken != null) {
        url = `/groups/${id}/accommodations?token=${publicAccessToken}`
      }

      axios
        .get(url).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  async getReservations(id) {

    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${id}/reservations`).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  async getImage(url) {

    return new Promise((resolve, reject) => {
      axios
        .get(url).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }





}
