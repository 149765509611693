<template>
  <b-list-group>
    <b-list-group-item class="dark-line-item">
      Paiement(s) effectué(s) jusqu'à maintenant
    </b-list-group-item>
    <div
      v-for="(payment, index) in currentReservation.reservation_payments"
      :key="index"
    >
      <b-list-group-item>
        Paiement #{{ index + 1 }}
        <b-badge class="ml-1" pill variant="light-secondary">
          {{ getPaymentType(payment) }}
        </b-badge>
        <b-badge class="ml-1" pill variant="light-success">
          {{ getPaymentStatus(payment) }}
        </b-badge>
        <span style="float: right">${{ (payment.amount / 100).toFixed(2) }}</span>
      </b-list-group-item>
    </div>
  </b-list-group>
</template>
<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BListGroup,
  BListGroupItem,
  BBadge,
} from "bootstrap-vue";
import { pricingUtils } from "@/shared/utils/pricing-utils";
import { reactive, ref, computed } from "@vue/composition-api";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  props: ["currentReservation"],
  setup(props) {
    const { getRoomPricingsFromReservation } = pricingUtils();
    const getRoomsPricings = computed(() => {
      return getRoomPricingsFromReservation(props.currentReservation);
    });

    return {
      getRoomsPricings,
    };
  },
  methods: {
    getPaymentStatus(payment) {
      if (payment.type !== "stripe") {
        return "Succès";
      }

      if (
        payment.stripe_payments &&
        Array.isArray(payment.stripe_payments) &&
        payment.stripe_payments.length > 0
      ) {
        const stripePayment = payment.stripe_payments[0];
        if (stripePayment.status === "succeeded") {
          return "Succès";
        } else {
          return "Échec";
        }
      }
      return "Succès";
    },
    getPaymentType(payment) {
      switch (payment.type) {
        case 'stripe':
          return 'Stripe';
          case 'moneris':
          return 'Moneris';
        case 'manual':
          return 'Manuel';
        default:
          return '';
      }
    }
  },
};
</script>

<style>
.card-footer {
  display: flex;
  flex-direction: row-reverse;
}

[dir] .list-group-item {
  background-color: #fff;
  border: 1px solid rgba(34, 41, 47, 0.125);
  padding: 5px;
}

.dark-line-item,
.dark-line-item:hover {
  background-color: #015a9c !important;
  color: white;
}

.children-pricing-border-top {
  border-top: 1px solid #4b4b4b;
}
</style>
