var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('h1',{staticClass:"section-label mx-0 mb-2 mt-1"},[_vm._v(" Informations personnelles ")])]),_c('b-col',{attrs:{"md":"4"}},[(_vm.canCopy)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.copyFromFirstTraveler.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"UsersIcon","size":"15"}}),_vm._v(" Copier données du 1er voyageur ")],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"Type"}},[(!_vm.isChildTraveler)?_c('v-select',{staticClass:"style-chooser",attrs:{"clearable":false,"disabled":true,"searchable":false,"value":"Adulte"}}):_c('validation-provider',{attrs:{"name":"Type de voyageur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.getAgeCategories(),"clearable":false,"label":"text","reduce":function (option) { return option.value; }},model:{value:(_vm.traveler.type),callback:function ($$v) {_vm.$set(_vm.traveler, "type", $$v)},expression:"traveler.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticStyle:{"margin-top":"25px"}},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":true,"unchecked-value":false,"disabled":_vm.hasPrimary && !_vm.traveler.reservation_owner && _vm.isChildTraveler},model:{value:(_vm.traveler.reservation_owner),callback:function ($$v) {_vm.$set(_vm.traveler, "reservation_owner", $$v)},expression:"traveler.reservation_owner"}},[_vm._v(" Contact principal ")])],1)])],1),_c('b-row',[(_vm.isPublic)?_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"show":""}},[_c('p',{staticClass:"p-1"},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"26"}}),_vm._v(" Votre prénom et nom doivent être identique à votre passport ")],1)])],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Prénom voyageur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Prénom","label-for":"Prénom"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.first_name),callback:function ($$v) {_vm.$set(_vm.traveler, "first_name", $$v)},expression:"traveler.first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"2ème prénom","label-for":"2ème prénom"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{attrs:{"name":"checkbox-input"},model:{value:(_vm.hasSecondName),callback:function ($$v) {_vm.hasSecondName=$$v},expression:"hasSecondName"}})],1),_c('b-form-input',{attrs:{"disabled":!_vm.hasSecondName},model:{value:(_vm.traveler.middle_name),callback:function ($$v) {_vm.$set(_vm.traveler, "middle_name", $$v)},expression:"traveler.middle_name"}})],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Nom voyageur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom","label-for":"Nom"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.last_name),callback:function ($$v) {_vm.$set(_vm.traveler, "last_name", $$v)},expression:"traveler.last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Date de naissance","label-for":"Date de naissance"}},[_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"departure-date","type":"date","max":"2100-01-01"},model:{value:(_vm.birthdayDate),callback:function ($$v) {_vm.birthdayDate=$$v},expression:"birthdayDate"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Genre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"Genre"}},[_vm._v("Genre "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:(
                  'F - Féminin<br>M – Masculin<br>X – Autre genre'
                ),expression:"\n                  'F - Féminin<br>M – Masculin<br>X – Autre genre'\n                ",modifiers:{"hover":true,"top":true,"html":true}}],staticClass:"text-muted cursor-pointer",attrs:{"icon":"HelpCircleIcon","size":"21"}})],1),_c('v-select',{class:{ 'v-select-is-invalid': errors.length > 0 },attrs:{"id":"group-type","options":_vm.genderType,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","reduce":function (text) { return text.value; }},model:{value:(_vm.traveler.gender),callback:function ($$v) {_vm.$set(_vm.traveler, "gender", $$v)},expression:"traveler.gender"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Numéro de télèphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de téléphone cellulaire","label-for":"Numéro de téléphone cellulaire"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.phone),callback:function ($$v) {_vm.$set(_vm.traveler, "phone", $$v)},expression:"traveler.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Couriel","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Courriel","label-for":"Courriel"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.email),callback:function ($$v) {_vm.$set(_vm.traveler, "email", $$v)},expression:"traveler.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mb-2 mt-1"},[_vm._v("Adresse")])])],1),_c('b-row',[(!_vm.isGroupX)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Adresse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Adresse","label-for":"Adresse","description":_vm.mandatory_description}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.address),callback:function ($$v) {_vm.$set(_vm.traveler, "address", $$v)},expression:"traveler.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1536587486)})],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Code postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Code Postal","label-for":"Code Postal"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('A#A #A#'),expression:"'A#A #A#'"}],class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.postal_code),callback:function ($$v) {_vm.$set(_vm.traveler, "postal_code", $$v)},expression:"traveler.postal_code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isGroupX)?_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Ville"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ville","label-for":"Ville","description":_vm.mandatory_description}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.city),callback:function ($$v) {_vm.$set(_vm.traveler, "city", $$v)},expression:"traveler.city"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2748067919)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Province","label-for":"Province"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.province),callback:function ($$v) {_vm.$set(_vm.traveler, "province", $$v)},expression:"traveler.province"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2332140428)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pays","label-for":"Pays"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.country),callback:function ($$v) {_vm.$set(_vm.traveler, "country", $$v)},expression:"traveler.country"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,442879646)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mb-2 mt-1"},[_vm._v("Nom du contact d'urgence")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nom contact d'urgence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom du contact d'urgence","label-for":"Nom du contact d'urgence","description":_vm.mandatory_description}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.emergency_contact_name),callback:function ($$v) {_vm.$set(_vm.traveler, "emergency_contact_name", $$v)},expression:"traveler.emergency_contact_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Téléphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Téléphone","description":_vm.mandatory_description}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.emergency_contact_phone),callback:function ($$v) {_vm.$set(_vm.traveler, "emergency_contact_phone", $$v)},expression:"traveler.emergency_contact_phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-1",attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0"},[_vm._v("Information médicale")]),_c('small',[_c('i',[_vm._v("Si votre état de santé nécessite des besoins spéciaux, si vous avez des allergies ou un régime alimentaire particulier, ceux-ci doivent être mentionnés au moment de la réservation.")])])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"besoins particuliers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Condition de santé préexistante qui nécessite des besoins particuliers?","label-for":"Condition de santé préexistante qui nécessite des besoins particuliers?"}},[_c('b-form-radio-group',{staticClass:"medical-radios",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.traveler.prior_medical_condition),callback:function ($$v) {_vm.$set(_vm.traveler, "prior_medical_condition", $$v)},expression:"traveler.prior_medical_condition"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.traveler.prior_medical_condition)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Détails de la condition","rules":{ required: _vm.traveler.prior_medical_condition }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Détails de la condition","label-for":"Détails de la condition"}},[_c('b-form-textarea',{attrs:{"rows":"4","size":"sm","placeholder":"Détails sur la condition préexistante"},model:{value:(_vm.traveler.preexisting_condition_details),callback:function ($$v) {_vm.$set(_vm.traveler, "preexisting_condition_details", $$v)},expression:"traveler.preexisting_condition_details"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2966328263)})],1):_vm._e()],1),_c('hr',{staticClass:"my-2"}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Allergies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Avez-vous des allergies?","label-for":"Avez-vous des allergies?"}},[_c('b-form-radio-group',{staticClass:"medical-radios",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.traveler.has_allergies),callback:function ($$v) {_vm.$set(_vm.traveler, "has_allergies", $$v)},expression:"traveler.has_allergies"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.traveler.has_allergies)?_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Allergies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Veuillez indiquer les allergies","label-for":"Veuillez indiquer les allergies"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.allergies),callback:function ($$v) {_vm.$set(_vm.traveler, "allergies", $$v)},expression:"traveler.allergies"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3325169464)})],1):_vm._e(),(_vm.traveler.has_allergies)?_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"EpiPen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Voyagez-vous avec votre EpiPen?","label-for":"Voyagez-vous avec votre EpiPen?"}},[_c('b-form-radio-group',{staticClass:"medical-radios",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.yesNoOptions,"value":true,"unchecked-value":false},model:{value:(_vm.traveler.requires_epipen_on_self),callback:function ($$v) {_vm.$set(_vm.traveler, "requires_epipen_on_self", $$v)},expression:"traveler.requires_epipen_on_self"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1369306914)})],1):_vm._e()],1),_c('hr',{staticClass:"my-2"}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"numéro de membre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?","label-for":"Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?"}},[_c('b-form-radio-group',{staticClass:"medical-radios",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.traveler.has_membership),callback:function ($$v) {_vm.$set(_vm.traveler, "has_membership", $$v)},expression:"traveler.has_membership"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.traveler.has_membership)?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"numéro de membre","rules":{ required: _vm.traveler.has_membership }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de membre","label-for":"Numéro de membre"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.membership_number),callback:function ($$v) {_vm.$set(_vm.traveler, "membership_number", $$v)},expression:"traveler.membership_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1703756536)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Nom de la compagnie","rules":{ required: _vm.traveler.has_membership }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom de la compagnie associée au numéro","label-for":"Nom de la compagnie associée au numéro"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.traveler.membership_company),callback:function ($$v) {_vm.$set(_vm.traveler, "membership_company", $$v)},expression:"traveler.membership_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1705018140)})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }