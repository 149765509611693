import {useApplicationContext} from "@/shared/composables/use-application-context";

export const pricingUtils = () => {
  const {logger} = useApplicationContext();

  const getRoomPricingsFromReservation = (reservation) => {
    try{
      let rooms = [];
      reservation.package_reservations?.forEach(packageReservation => {
        let room = [];
        let currentOccupationTypePricing = packageReservation.occupation_type.occupation_type_pricing;
            
        let paxIndex = {}
        packageReservation.travelers.forEach((pax, index) => {
          let paxType = pax.type;

          if (paxIndex[paxType] >= 0) {
            paxIndex[paxType]++
          } else {
            paxIndex[paxType] = 0;
          }
          
          let result = currentOccupationTypePricing.find(price => {
         
            return ((price.type === paxType) && (price.index == paxIndex[paxType]))
          });
          room.push(result)
          
        })

        rooms.push(room);
      })
           

      return rooms;
      } catch (e) {
        console.error(e)
      }
  }

  const formatPaymentDueForDeposit = (paymentDue) => {
    try{
      let deposits = [];
      let totalAmount = 0;

      paymentDue.forEach(payment => {
        deposits.push({
          travelerName: `${payment.traveler.first_name} ${payment.traveler.last_name}`,
          type : payment.traveler.type,
          amount: payment.amount
        })
        totalAmount = totalAmount + payment.amount;
      })

      return {
        deposits,
        totalAmount
      }

      } catch (e) {
        console.error(e)
      }
  }


  return {
    getRoomPricingsFromReservation,
    formatPaymentDueForDeposit
  }

}
