<template>
  <svg
    :height="size"
    :width="size"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      v-if="active"
      :fill="color"
      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"
    />
    <path
      v-else
      :fill="color"
      d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
    />
  </svg>
</template>

<script>
export default {
  name: "SelectionCircle",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 18,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
