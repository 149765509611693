<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      
      <div v-if="!hasPackageReservations" class="mt-4">
        <b-card  title="Ajouter un forfait à votre réservation" header-tag="header" body-class="text-center" class="mx-auto" style="max-width: 80%; min-width: 60%">

          <b-card-text>
            Sélection du forfait voyage et confirmation du nombre de voyageurs
          </b-card-text>

          <b-button variant="primary"  @click="showAddPackageModal">Débuter</b-button>
        </b-card>
      </div>
      <b-card v-else title="Votre réservation" header-tag="header" class="mt-2" >
        <template #header>
          <h3> Confirmation du forfait & chambre </h3>
          <b-button  variant="outline-primary" @click="showAddPackageModal"
            :disabled="!(currentReservation.status!='SUBMITTED' || canViewBasedOnRole(currentAuthUser, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]))"
          >Modifier</b-button>
        </template>
        <div>
          <b-row>
          <b-col cols='8'>
            <b-row>
              <b-col cols="3">
                <div>
                  <h5 class=" mb-75">
                  Numéro de réservation
                  </h5>
                  <b-card-text>
                    {{ currentReservation.reservation_number }}
                  </b-card-text>
                </div>
              </b-col>
              <b-col cols="3">
                <div>
                  <h5 class=" mb-75">
                    Forfait sélectionné
                  </h5>
                  <b-card-text>
                    {{ currentReservation.package_reservations[0].group_package.name }}
                  </b-card-text>
                </div>
              </b-col>
              <b-col cols="3">
                <div>
                  <h5 class="mb-75">
                    Nombre de chambre(s)
                  </h5>
                  <b-card-text>
                    {{ currentReservation.package_reservations.length }}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">

              <b-col cols="3" v-for="room,index in currentReservation.package_reservations" :key="index">
                <div>
                  <h5 class="mb-75">
                    Chambre #{{index+1}}
                  </h5>
                  <ul style="list-style: none; padding-inline-start: 2px">
                    <li v-for="traveler in typeToNumberTravelers(getRoomOccupationType(room))" :key="'adult-' + traveler">

                      <span class="bullet bullet-sm mr-1 bullet-primary"></span>
                      <small class="text-capitalize">Adulte</small>
                    </li>
                    <li v-for="traveler in (room.occupation_type.travelers_max - typeToNumberTravelers(getRoomOccupationType(room)))" :key="'kid-' + index + '-' + traveler">
                      <span class="bullet bullet-sm mr-1 bullet-primary"></span>
                      <small class="text-capitalize">Enfant (optionnel)</small>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols='4' v-if=tenantConfig.paymentEnabled>
          <b-row>
            <b-col v-if='hasPackageReservations'>
              <ReservationPricingCard :current-reservation=currentReservation />

            </b-col>
          </b-row>
          </b-col>
          </b-row>
        </div>
        <template #footer>
          <b-button variant="primary" v-on:click="$emit('nextTab')">Suivant</b-button>
        </template>
      </b-card>
      <GroupReservationPackageModal :v-if="showModalWindow" :current-agent="currentAgent" ref='add-reservation-package-modal'  :agents="agents" :current-reservation=currentReservation :unavailable-packages=unavailablePackages :current-packages=currentPackages @submitUpdateReservationPackage="submitUpdateReservationPackage" @submitReservationPackage=submitReservationPackage @cancel="showModalWindow = false" />
    </b-col>
  </b-row>
</template>
<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import GroupReservationPackageModal from './GroupReservationPackageModal.vue'
import Roles from '@/modules/authnz/models/role';
import {reactive, ref, computed} from "@vue/composition-api";
import {pricingUtils} from "@/shared/utils/pricing-utils";
import ReservationPricingCard from '@/views/groups/group/GroupReservations/EditReservation/EditPayment/ReservationPricingCard.vue'
import {AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";

import store from '@/store/index'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupReservationPackageModal,
    BListGroup,
    BListGroupItem,
    ReservationPricingCard
  },
  props:[
    'currentPackages',
    'currentReservation',
    'currentAgent',
    'unavailablePackages',
    'currentAuthUser',
    'agents'
  ],
  data() {
    return {
      hasBasicInfo:false ,
      currentReservationPackage: {},
    }
  },
  setup(props) {
    const {getRoomPricingsFromReservation} = pricingUtils();

    let tenantConfig = reactive({
      paymentEnabled: false
    });

    try {
      tenantConfig.paymentEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable;
    } catch (e) {
      console.error(e)
    }

    let showModalWindow = ref(false);
    let rooms = reactive([])

    let canViewBasedOnRole = (currentRole, allowedRoles) => {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles)
    }

   const getRoomsPricings = computed(() => {
    return getRoomPricingsFromReservation(props.currentReservation)
  })
    

    return {
      Roles,
      canViewBasedOnRole,
      tenantConfig,
      showModalWindow,
      getRoomsPricings
    }
  },
  created() {
    if (this.$route.query.package_id) {
      this.$nextTick(() => {
        this.showAddPackageModal();
      })
    }
  },
  computed: {
    hasPackageReservations() {
      return this.currentReservation.package_reservations && this.currentReservation.package_reservations.length > 0;
    },
    typeToNumberTravelers() {
      const types = {
        SINGLE: 1,
        DOUBLE: 2,
        TRIPLE: 3,
        QUADRUPLE: 4,
      }
      return type => types[type];
    },
  },
  methods: {
    showAddPackageModal() {
      this.showModalWindow = true;
      this.$nextTick(function() {
        this.$refs['add-reservation-package-modal'].$children[0].show()
      });
    },
    submitReservationPackage(reservation) {
      this.currentReservationPackage = reservation;
      this.$emit('submitReservationPackage', reservation)

    },
    submitUpdateReservationPackage(reservation,dofunction) {
      this.currentReservationPackage = reservation;
      this.$emit('submitUpdateReservationPackage', reservation,dofunction)
    },
    getRoomOccupationType(room) {
      try {
        return room.occupation_type.type
      } catch {
        return []
      }
    }
  },

}
</script>

<style>
.card-footer {
  display: flex;
  flex-direction: row-reverse;
}


  [dir] .list-group-item {
      background-color: #fff;
      border: 1px solid rgba(34, 41, 47, 0.125);
      padding: 5px;
  }

  .dark-line-item, .dark-line-item:hover {
    
    background-color: #015a9c !important;
    color: white;
  }

  .children-pricing-border-top {
    border-top: 1px solid #4b4b4b;
  }

</style>
