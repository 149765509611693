import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useApiRequestMappers} from "@/shared/composables/use-api-request-mappers";

export const useReservationApi = () => {
  const {logger, httpClient} = useApplicationContext();
  const {keysToSnakeCase, keysToCamel} = useApiRequestMappers();


  const createPaymentIntent = async (params, publicAccessToken) => {

    let url = `/payment`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    const requestData = keysToSnakeCase(params);

    try {
      const response = await httpClient.post(url, requestData);
      //const report = keysToCamel(response.data);

      return response;
    } catch (error) {
      logger.error('Failed to generate payment intent', error);
      throw  error;
    }
  };

  const updatePaymentAmounts = async (group_id, reservation_id, amounts, publicAccessToken) => {
    let url = `/payment/${group_id}/reservation/${reservation_id}/amounts`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      const response = await httpClient.post(url, {payments: amounts});

      return response;
    } catch (error) {
      logger.error('Failed to generate payment intent', error);
      throw  error;
    }
  };

  const getPaymentAmounts = async (group_id, reservation_id, publicAccessToken) => {
    let url = `/payment/${group_id}/reservation/${reservation_id}/amounts`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      const response = await httpClient.get(url);

      return response;
    } catch (error) {
      logger.error('Failed to generate payment intent', error);
      throw  error;
    }
  };

  const getPaymentDue = async (group_id, reservation_id, publicAccessToken) => {
    let url = `/groups/${group_id}/reservations/${reservation_id}/payment_due`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      const response = await httpClient.get(url);
      //const report = keysToCamel(response.data);

      return response;
    } catch (error) {
      logger.error('Failed to get payment due', error);
      throw  error;
    }
  };
  

  const getFICAVForReservation = async (group_id, reservation_id, publicAccessToken) => {
    let url = `/groups/${group_id}/reservations/${reservation_id}/ficav_due`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      const response = await httpClient.get(url);
      //const report = keysToCamel(response.data);

      return response;
    } catch (error) {
      logger.error('Failed to get ficav due', error);
      throw  error;
    }
  };

  const createPaymentRegister = async (params, group_id, reservation_id, publicAccessToken) => {
    let url =`/payment/${group_id}/reservation/${reservation_id}/register/stripe`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    let formattedParams = {
      id: params.id,
      object: params.object,
      amount: params.amount,
      status: params.status,
      currency: params.currency,
      captureMethod: params.capture_method,
      clientSecret: params.client_secret,
      paymentMethod: params.payment_method,
      livemode: params.livemode
    }
    const requestData = keysToSnakeCase(formattedParams);

    try {
      const response = await httpClient.post(url, requestData);
      //const report = keysToCamel(response.data);

      return response;
    } catch (error) {
      logger.error('Failed to generate payment intent', error);
      throw  error;
    }
  };

  const sendToPCVoyage = async (group_id, reservation_id) => {

    try {
      const response = await httpClient.put(`/groups/${group_id}/reservations/${reservation_id}/pcvoyage`);
      //const report = keysToCamel(response.data);

      return response;
    } catch (error) {
      logger.error('Failed to send To PCVoyage', error);
      throw  error;
    }
  };

  const getMonerisCheckoutTicket = async (reservation, payment_id, publicAccessToken) => {
    let url = `/payment/${reservation.group_id}/reservation/${reservation.id}/${payment_id}/moneris/ticket`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      logger.debug(`send To Moneris Checkout Ticket`);

      let formattedParams = {}
      const requestData = keysToSnakeCase(formattedParams);

      const response = await httpClient.post(url, requestData);
      //const report = keysToCamel(response.data);
      logger.debug(response)
      logger.debug(`getting Moneris Checkout Ticket`);
      return response;
    } catch (error) {
      logger.error('Failed to get Moneris Checkout Ticket', error);
      throw  error;
    }
  };

  const getMonerisCheckoutReceipt = async (group_id, reservation_id, ticket_id, publicAccessToken) => {
    let url =`/payment/${group_id}/reservation/${reservation_id}/register/moneris`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      logger.debug(`send To Moneris Checkout Receipt`);

      let formattedParams = {
        ticketId: ticket_id
      }

      const requestData = keysToSnakeCase(formattedParams);

      const response = await httpClient.post(url,requestData);
      //const report = keysToCamel(response.data);
      logger.debug(response)
      logger.debug(`getting Moneris Checkout Receipt`);
      return response;
    } catch (error) {
      logger.error('Failed to get Moneris Checkout Receipt', error);
      throw  error;
    }
  };

  return {
    createPaymentIntent,
    createPaymentRegister,
    getPaymentDue,
    sendToPCVoyage,
    getFICAVForReservation,
    getMonerisCheckoutTicket,
    getMonerisCheckoutReceipt,
    updatePaymentAmounts,
    getPaymentAmounts
  };
};
