var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"12","md":"12"}},[(_vm.currentGroupId)?_c('div',{staticClass:"mt-1"},[_c('GroupReservationSummary',{attrs:{"current-agent":_vm.currentAgent,"current-group-id":_vm.currentGroupId,"current-reservation":_vm.currentReservation,"currentPackages":_vm.currentPackages,"has-exceeded-inventory":_vm.hasExceededInventory,"payment-enabled":_vm.paymentEnabled}}),(_vm.paymentEnabled && _vm.paymentProcessor === 'stripe')?_c('group-reservation-stripe-payment',{ref:"payment-form",attrs:{"current-auth-user":_vm.currentAuthUser,"current-reservation":_vm.currentReservation},on:{"enablePaymentButton":_vm.enablePaymentButton,"submit":_vm.submitReservation}}):(
          _vm.currentReservation.id &&
          _vm.paymentEnabled &&
          _vm.paymentProcessor === 'moneris'
        )?_c('group-reservation-moneris-payment',{ref:"payment-form",attrs:{"current-auth-user":_vm.currentAuthUser,"current-reservation":_vm.currentReservation},on:{"enablePaymentButton":_vm.enablePaymentButton,"submit":_vm.submitReservation}}):_vm._e(),(
          _vm.paymentProcessor !== 'moneris' &&
          _vm.currentReservation.status != 'SUBMITTED' &&
          _vm.hasPrimaryContact &&
          !_vm.hasExceededInventory
        )?_c('b-card',{attrs:{"title":"Confirmation de la réservation des voyageurs","header-tag":"header","body-class":"text-center"}},[_c('b-card-text',[_vm._v(" Votre réservation est prête à être soumise. ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success","disabled":!_vm.isSubmitButtonEnable || _vm.loading},on:{"click":_vm.submitReservation}},[(_vm.paymentEnabled)?_c('span',[_vm._v("Confirmer et payer")]):_c('span',[_vm._v("Confirmer")])])],1):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }