<template>
  <b-modal
    ref="summaryEmailModalReservation"
    @submit="submit"
    :title="'Sommaire de réservation - #' + reservation.reservation_number"
    hide-footer
  >
    <validation-provider
      #default="{ errors }"
      name="Adresse courriel"
      rules="required"
    >
      <b-form-group label="Adresse courriel à laquelle envoyer le sommaire de la réservation" label-for="Adresse courriel">
        <b-form-input
          v-model="contact_email"
          :class="{ 'is-invalid': errors.length > 0 }"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <hr />

    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        size="md"
        @click="cancel"
        class="mr-1"
      >
        Annuler
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="md"
        @click="submit"
      >
        Envoyer
      </b-button>
    </div>
  </b-modal>
</template>
  
  <script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,
} from "bootstrap-vue";
import {localize, ValidationProvider, ValidationObserver} from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

localize('fr', fr);

export default {
  name: "ReservationSummaryEmailModal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["reservation"],
  data() {
    return {
      contact_email: "",
    };
  },
  computed: {
    email: {
      get() {
        return this.reservation.contact_email;
      },
      set(newValue) {
        this.contact_email = newValue;
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.contact_email);
    },
    cancel() {
      this.$refs["summaryEmailModalReservation"].hide();
    },
  },
};
</script>
  