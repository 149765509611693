<template>
  <b-modal
    id="modal-select2"
    title="Liste des voyageurs"
    ref="listtraveler"
    ok-title="Enregistrer"
    cancel-title="Annuler"
    cancel-variant="outline-secondary"
    size="lg"
    @ok='UpdateTravelers'
    no-close-on-backdrop
  >
    <p>Cliquez sur les icônes à droite des noms des voyageurs inscrits pour les supprimer.</p>
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(traveler,index) in this.listTraveler" :key="index">
        <div>
      <feather-icon icon="UserIcon" size="18"  />&nbsp;<span>{{traveler.first_name + ' ' + traveler.last_name }}</span>
        </div>
        <b-button

          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon rounded-circle "
          @click="deleteRow(traveler,index)"
          v-if="countNbDelete<nbDelete"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button
          >
        <b-button

          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-transparent"
          class="btn-icon rounded-circle "
         v-else
        >
          <span class="text-danger" v-if="traveler.deleted===1">Supprimé</span>
          <span class="text-success" v-else>Conservé</span>
        </b-button
        >
      </b-list-group-item>
    </b-list-group>

  </b-modal>
</template>

<script>
    import BCardCode from "@core/components/b-card-code";
    import {
      BButton,
      BCardText,
      BListGroup, BListGroupItem, BBadge,
      BTable,
      BCol,
      BForm, BFormCheckbox, BFormCheckboxGroup,
      BFormFile,
      BFormGroup,
      BFormInput, BFormInvalidFeedback, BFormRadio, BFormTextarea, BInputGroup, BInputGroupPrepend,
      BModal,
      BRow, BTab, BTabs, VBModal
    } from "bootstrap-vue";
    import vSelect from "vue-select";
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import Ripple from "vue-ripple-directive";

    export default {
        name: "ListTraveler",
      components: {
        BCardCode,
        BButton,
        BModal,
        BForm,
        BListGroup, BListGroupItem, BBadge,
        BTable,
        BFormInput,
        BCardText,
        BFormGroup,
        vSelect,
        BRow,
        BCol,
        BInputGroup,
        BFormCheckbox,
        BFormCheckboxGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BTabs,
        BFormFile,
        BTab,
        BFormInvalidFeedback,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        BFormRadio
      },
      directives: {
        'b-modal': VBModal,
        Ripple,
      },
      props:['listTraveler','nbDelete','roomIndex'],
      computed:{

      },
      data(){
        return {
          countNbDelete:0,
        }
      },
      methods: {
          deleteRow(item){
            this.$swal({
              title: 'Suppression voyageur',
              text: "Êtes-vous certain de vouloir supprimer ce voyageur?",
              icon: 'warning',
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonText: 'Supprimer',
              cancelButtonText: 'Annuler',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value){
                  item.deleted=1;
                  this.countNbDelete++;
                }
              })
          },
        UpdateTravelers(){
            this.$refs.listtraveler.hide();
            this.$emit('UpdateTravelers',this.listTraveler,this.roomIndex)
        }
      },
    }
</script>

<style scoped>

</style>
