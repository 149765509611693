<template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
      <div v-if="!currentReservation.extra_information" class="mt-4">
        <b-card  title="Ajouter des informations supplémentaire" header-tag="header" body-class="text-center" class="mx-auto" style="max-width: 80%; min-width: 60%">
          <b-card-text>
            Cette étape est optionnelle, veuillez cliquer sur le bouton <strong>Ajouter</strong> si vous désirez ajouter des informations supplémentaires concernant cette réservation. 
          </b-card-text>

          <b-button variant="primary"  
            @click="showInsuranceModal"
            :disabled="!(currentReservation.status!='SUBMITTED' || canViewBasedOnRole(currentAuthUser, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]))"
          >
            Ajouter
          </b-button>
          <b-button variant="outline-primary" class="ml-1" v-on:click="$emit('nextTab')">Suivant</b-button>

        </b-card>
      </div>
        <b-card  v-if="currentReservation.extra_information" header-tag="header" class="mt-2" >
          <template #header>
              <h3>Informations supplémentaires</h3>
              <b-button variant="outline-primary" 
                @click="showInsuranceModal"
                :disabled="!(currentReservation.status!='SUBMITTED' || canViewBasedOnRole(currentAuthUser, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]))"
              >
              Modifier</b-button>
          </template>

          <div v-if="!isTransat">
          <b-row v-if="currentReservation.has_trip_insurance">
            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Assurance du client
                </h5>
                <b-card-text>
                  <ul v-for="type in currentReservation.insurance_types">
                    <li >{{insuranceTypes(type)}}</li>
                  </ul>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Compagnie d'assurance
                </h5>
                <b-card-text>
                  {{currentReservation.insurer_name}}
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Numéro de contrat
                </h5>
                <b-card-text>
                  {{currentReservation.insurance_contract_number}}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>


            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Assurance du client
                </h5>
                <b-card-text>
                  Aucune
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Est-ce que le client désire une soumission?
                </h5>
                <b-card-text>
                  {{currentReservation.wants_insurance_submission?"Oui":"Non"}}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="currentReservation.has_membership" class="mt-3">

            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Numéro de membre
                </h5>
                <b-card-text>
                  {{ currentReservation.membership_number }}
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Nom de la compagnie associée au numéro
                </h5>
                <b-card-text>
                  {{ currentReservation.membership_company }}
                </b-card-text>
              </div>
            </b-col>

          </b-row>
          <b-row v-else class="mt-3">

            <b-col cols="3">
              <div>
                <h5 class=" mb-75">
                  Numéro de membre
                </h5>
                <b-card-text>
                 Aucun
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          </div>
          <b-row  >
            <b-col cols="6">
              <div>
                <h5 class=" mb-75">
                  Informations supplémentaires sur la réservation
                </h5>
                <b-card-text v-if="currentReservation.extra_information && !(currentReservation.extra_information == '')">
                  {{ currentReservation.extra_information }}
                </b-card-text>
                <b-card-text v-else>
                  Aucune
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <template #footer class="card-footer">
            <b-button variant="primary" v-on:click="$emit('nextTab')">Suivant</b-button>
          </template>
        </b-card>
      </b-col>
        <GroupReservationDetailsModal ref='add-reservation-insurance-modal' @submitReservationDetails=submitReservationDetails :current-reservation="currentReservation"/>

    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel } from 'bootstrap-vue'
import GroupReservationDetailsModal from './GroupReservationDetailsModal.vue'
import {useApplicationContext} from "@/shared/composables/use-application-context";
import Roles from '@/modules/authnz/models/role';

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupReservationDetailsModal
  },
  props:['currentReservation', 'currentAuthUser'],
  data() {
    return {
      isTransat: true,
      insuranceAndPayment: {},
    }
  },
  setup(props) {
    const {refOf} = useApplicationContext();

    const showInsuranceModal = () => {
      refOf('add-reservation-insurance-modal').$children[0].show()
    }


    let canViewBasedOnRole = (currentRole, allowedRoles) => {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles)
    }


    return {
      showInsuranceModal,
      Roles,
      canViewBasedOnRole
    }
  },
  computed:{
    insuranceTypes() {
      const types = {
        MEDICAL: "Médical",
        TRIP_CANCELLATION: "Annulation et pertubation voyage",
        LUGGAGE: "Bagages",
        COVID: "Covid-19",
      }
      return type => types[type];
    },
  },
  methods: {
    submitReservationDetails(extra_information) {

      this.currentReservation.extra_information = extra_information;
      this.$emit('submitReservationDetails', this.currentReservation)
    },
  }
}
</script>

<style>

</style>
